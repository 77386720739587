import React from 'react';
import { motion } from 'framer-motion';
import { FaBrain, FaCode, FaChartLine, FaNetworkWired, FaTools, FaBookOpen } from 'react-icons/fa';

const services = [
  {
    title: "Data Science & Analytics",
    icon: <FaChartLine className="text-4xl mb-4" />,
    description: "Empowering decisions with data-driven insights using advanced analytics, machine learning, and blockchain-enhanced data security.",
    tasks: [
      "Healthcare Fraud Detection",
      "Predictive Modeling",
      "Statistical Analysis",
      "Data Visualization",
      "Blockchain-enhanced Data Security",
      "Big Data Processing",
      "Time Series Forecasting",
      "Data Mining Techniques"
    ]
  },
  {
    title: "Software & Web Development",
    icon: <FaCode className="text-4xl mb-4" />,
    description: "Designing and developing high-quality, scalable software solutions across web and mobile platforms, including API design and integration.",
    tasks: [
      "Full-stack Web Development",
      "Mobile Applications",
      "API Design and Integration",
      "Cloud Solutions with AWS",
      "Real-time Systems",
      "UX/UI Design",
      "Database Management",
      "Security Compliance"
    ]
  },
  {
    title: "Research & Development",
    icon: <FaBookOpen className="text-4xl mb-4" />,
    description: "Engaging in cutting-edge research and innovation in AI, blockchain, and speech recognition technologies, including academic publications and collaborative projects.",
    tasks: [
      "Academic Research",
      "Technology Innovation",
      "Patent Filing",
      "Collaborative Projects",
      "Grant Writing",
      "Experimental Prototyping",
      "Scientific Analysis",
      "Data Ethics"
    ]
  },
  {
    title: "Technical Consulting & Strategy",
    icon: <FaTools className="text-4xl mb-4" />,
    description: "Providing expert advice and strategic planning to help businesses navigate and integrate complex technical solutions, focusing on modernization and efficiency.",
    tasks: [
      "Systems Analysis",
      "Tech Stack Modernization",
      "IT Strategy Planning",
      "Project Management",
      "Digital Transformation",
      "Technology Assessment",
      "Business Intelligence",
      "Change Management"
    ]
  },
  {
    title: "Blockchain & Security",
    icon: <FaNetworkWired className="text-4xl mb-4" />,
    description: "Implementing secure, decentralized technology solutions with blockchain, advanced encryption, and cryptographic security.",
    tasks: [
      "Smart Contracts",
      "Decentralized Applications (DApps)",
      "Cryptography & Data Security",
      "Blockchain for Business",
      "Secure Transactions",
      "Tokenization Strategies",
      "Ledger Integration",
      "Regulatory Compliance"
    ]
  },
  {
    title: "AI & Machine Learning",
    icon: <FaBrain className="text-4xl mb-4" />,
    description: "Developing cutting-edge AI solutions, including deep learning, neural networks, and NLP to drive innovation and efficiency.",
    tasks: [
      "Custom Machine Learning Models",
      "Deep Learning & Neural Networks",
      "Natural Language Processing",
      "AI-Driven Data Analysis",
      "AI Integration in Existing Systems",
      "Computer Vision",
      "Reinforcement Learning",
      "Algorithm Optimization"
    ]
  }
];

const Services = () => {
  return (
    <section className="py-20 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center text-blue-600 dark:text-blue-400"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Services
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-2"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="text-blue-600 dark:text-blue-400">{service.icon}</div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">{service.title}</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">{service.description}</p>
              <div className="flex flex-wrap mb-4">
                {service.tasks.map((task) => (
                  <span key={task} className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                    {task}
                  </span>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="mt-12 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <a 
            href="mailto:pavankumar.dubasi2019@gmail.com" 
            className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105 shadow-glow hover:shadow-glow-lg"
          >
            Get in Touch
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
